import React from 'react';
import styles from './Button.module.scss';

function Button({name, onClick, type, disabled, modeStyle = ['regular'], icon}) {
    return (
        <button
            className={
                [
                    styles.btn,
                    modeStyle.includes('full') && styles.full,
                    modeStyle.includes('regular') && styles.regular,
                    modeStyle.includes('redRegular') && styles.redRegular,
                    modeStyle.includes('border') && styles.border,
                ].join(' ')
            }
            type={type}
            disabled={disabled}
            onClick={onClick}
        >
            {icon && icon === 'trash' && (
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.33341 6.66667V11.3333M6.66675 6.66667V11.3333M4.00008 4V11.8667C4.00008 12.6134 4.00008 12.9865 4.14541 13.2717C4.27324 13.5226 4.47706 13.727 4.72795 13.8548C5.01288 14 5.38608 14 6.13135 14H9.86881C10.6141 14 10.9867 14 11.2717 13.8548C11.5226 13.727 11.7271 13.5226 11.8549 13.2717C12.0001 12.9868 12.0001 12.614 12.0001 11.8687V4M4.00008 4H5.33341M4.00008 4H2.66675M5.33341 4H10.6667M5.33341 4C5.33341 3.37874 5.33341 3.06827 5.43491 2.82324C5.57023 2.49654 5.82963 2.23682 6.15633 2.10149C6.40136 2 6.71216 2 7.33341 2H8.66675C9.288 2 9.59863 2 9.84366 2.10149C10.1704 2.23682 10.4299 2.49654 10.5652 2.82324C10.6667 3.06827 10.6667 3.37875 10.6667 4M10.6667 4H12.0001M12.0001 4H13.3334" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            )}
            {name}
        </button>
    );
}

export default Button;
