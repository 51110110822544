import React, { useState, useRef, useEffect } from 'react';
import io from 'socket.io-client';

import styles from './DeleteCheckDialog.module.scss';
import Button from '../Button/Button';
import { deletePaymentService } from '../../features/deletePayment/deletePaymentService';

function DeleteCheckDialog({user, checkId, number, onCancelDialog, onContinue}) {
    const [isProgress, setIsProgress] = useState(false);
    const [isFinishedProgress, setIsFinishedProgress] = useState(false);
    const [statusPercentage, setStatusPercentage] = useState(0);

    const [stepsStatus, setStepsStatus] = useState([]);
    const [steps, setSteps] = useState([]);

    const socket = useRef(null);

    useEffect(() => {
        socket.current = io(process.env.REACT_APP_WS_URL, {
            query: {
                user_id: user.id
            }
        });

        return () => {
            socket.current.disconnect();
        };
    }, [user.id]);

    useEffect(() => {
        if (isProgress) {
            const handleData = (data) => {
                if (data && data.job) {
                    const {steps} = data.job;
                    setStepsStatus(steps);
                    setStatusPercentage(data.job.completionPercentage);

                    if (data.job.status === 'completed') {
                        setTimeout(() => {
                            setIsProgress(false);
                            setIsFinishedProgress(true);
                        }, 1000);
                    }
                }
            };

            socket.current.on('delete-check', handleData);

            return () => {
                socket.current.off('delete-check', handleData);
            };
        }
    }, [isProgress]);

    useEffect(() => {
        if (isFinishedProgress) {
            socket.current.off('delete-check');
        }
    }, [isFinishedProgress]);

    const handleStartDelete = async () => {
        if (!checkId) {
            return;
        }

        try {
            const { steps } = await deletePaymentService.deleteCheck(checkId, user.id);
            setSteps(steps);
            setIsProgress(true);
        } catch (error) {
            console.error('Failed to start delete process:', error);
            setIsProgress(false);
            setSteps([]);
        }
    };

    const findStepStatus = (name) => {
        const find = stepsStatus.find(item => item.name === name);

        if (find) {
            return find.status;
        }

        return 'pending'
    }

    const findStepInProgressName = () => {
        const find = stepsStatus.find(item => item.status === 'in-progress');

        if (find) {
            return find.name;
        }

        return steps && steps.length > 0 && statusPercentage !== 100 ? steps[0].name : '-';
    }

    const cancelDialog = () => {
        if (isProgress) {
            return;
        }

        if (isFinishedProgress) {
            onContinue();
            return;
        }

        onCancelDialog();
    }

    return (
        <div className={styles.wrapperDeleteCheckDialog}>
            <div className={styles.dialog}>
                <button className={styles.btnClose} onClick={cancelDialog}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 16L12 12M12 12L8 8M12 12L16 8M12 12L8 16" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>

                <div className={[
                        styles.header,
                        isProgress && styles.isProgress,
                        isFinishedProgress && styles.done
                    ].join(' ')}
                >

                    {
                        isFinishedProgress
                        ? (
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 10L11 14L9 12M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21Z" stroke="#22C55E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        )
                        : (
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14 10V17M10 10V17M6 6V17.8C6 18.9201 6 19.4798 6.21799 19.9076C6.40973 20.2839 6.71547 20.5905 7.0918 20.7822C7.5192 21 8.07899 21 9.19691 21H14.8031C15.921 21 16.48 21 16.9074 20.7822C17.2837 20.5905 17.5905 20.2839 17.7822 19.9076C18 19.4802 18 18.921 18 17.8031V6M6 6H8M6 6H4M8 6H16M8 6C8 5.06812 8 4.60241 8.15224 4.23486C8.35523 3.74481 8.74432 3.35523 9.23438 3.15224C9.60192 3 10.0681 3 11 3H13C13.9319 3 14.3978 3 14.7654 3.15224C15.2554 3.35523 15.6447 3.74481 15.8477 4.23486C15.9999 4.6024 16 5.06812 16 6M16 6H18M18 6H20" stroke="#EF4444" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        )
                    }

                    <span className={styles.title}>
                        {!isProgress
                            ? isFinishedProgress ? 'Delete Done' : 'Delete Check'
                            : 'Delete Progress...'
                        }
                    </span>
                </div>

                <div className={[
                        styles.body,
                        isProgress && styles.isProgress,
                    ].join(' ')}
                >
                    {
                        !isProgress && !isFinishedProgress && (<>
                            <div className={styles.text}>
                                Do you really want to delete the check with the number: <strong>{number}</strong> ?
                            </div>
                            <div className={styles.actions}>
                                <Button
                                    name='Cancel'
                                    modeStyle={['full', 'border']}
                                    onClick={cancelDialog}
                                />
                                <Button
                                    name='Yes, Delete Check'
                                    modeStyle={['full', 'redRegular']}
                                    onClick={handleStartDelete}
                                />
                            </div>
                        </>)
                    }
                    {
                        isProgress && !isFinishedProgress && (<>
                            <div className={styles.progressInfo}>
                                <span>Delete:</span>
                                <strong className={styles.name}>{findStepInProgressName()}</strong>
                                <strong>{statusPercentage}%</strong>
                            </div>

                            <div className={styles.progressBar}>
                                <div
                                    className={styles.line}
                                    style={{
                                        width: `${statusPercentage}%`
                                    }}
                                ></div>
                            </div>

                            <div className={styles.wrapperItems}>
                                <div className={styles.headerItem}>Check Deletion progress</div>
                                {steps.map((item, index) => (
                                    <div key={'item_' + index} className={styles.item}>
                                        <span>{item.name}</span>
                                        {
                                            findStepStatus(item.name) === 'completed'
                                            ? <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 6.66667L7.33333 9.33333L6 8M8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14Z" stroke="#22C55E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                            : <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8 4.66667V8H11.3333M8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14Z" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        }
                                    </div>
                                ))}
                            </div>
                        </>)
                    }

                    {
                        isFinishedProgress && (<>
                            <div className={styles.text}>
                                Check number: <strong>{number}</strong> has been successfully deleted!
                            </div>
                            <div className={styles.actions}>
                                <Button
                                    name='Continue'
                                    modeStyle={['full', 'regular']}
                                    onClick={cancelDialog}
                                />
                            </div>
                        </>)
                    }
                </div>
            </div>
            <div className={styles.overlay} onClick={cancelDialog}></div>
        </div>
    );
}

export default DeleteCheckDialog;
