import axiosClient from '../axiosClient';

const getCheck = async (checkNumber) => {
    try {

        const response = await axiosClient.get(`/check/search?checkNumber=${checkNumber}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}

const getClaims = async (checkId, page = 1) => {
    try {

        const response = await axiosClient.get(`/check/${checkId}/claims?page=${page}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}

const getFreightBills = async (claimId, page = 1) => {
    try {
        const response = await axiosClient.get(`/claim/${claimId}/freight-bills?page=${page}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}

const getUser = async (userId) => {
    try {
        const response = await axiosClient.get(`/user/${userId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}

const deleteCheck = async (checkId, userId) => {
    try {
        const response = await axiosClient.delete(`/delete-check/${checkId}`, {
            headers: {userid: userId}
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const deletePaymentService = {
    getCheck,
    getClaims,
    getFreightBills,
    getUser,
    deleteCheck,
};
