import React from 'react';
import styles from './DeletePaymentInfo.module.scss';

function  DeletePaymentInfo({amount, checkCurrency, currencyRate, checkDate, checkIssued, claimPaymentNumber}) {
    return (
        <div className={styles.deletePaymentInfo}>
            <div className={styles.item}>
                <div className={styles.title}>Amount</div>
                <div className={styles.value}>{amount}</div>
            </div>
            <div className={styles.item}>
                <div className={styles.title}>Check Currency</div>
                <div className={styles.value}>{checkCurrency}</div>
            </div>
            <div className={styles.item}>
                <div className={styles.title}>Currency Rate</div>
                <div className={styles.value}>{currencyRate}</div>
            </div>
            <div className={styles.item}>
                <div className={styles.title}>Check Date</div>
                <div className={styles.value}>
                  {
                    new Date(checkDate).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit'
                    })
                  }
                </div>
            </div>
            <div className={styles.item}>
                <div className={styles.title}>Check Issued</div>
                <div className={styles.value}>
                  {
                    new Date(checkIssued).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit'
                    })
                  }
                </div>
            </div>
            <div className={styles.item}>
                <div className={styles.title}>Check Header Number</div>
                <div className={styles.value}>{claimPaymentNumber}</div>
            </div>
        </div>
    );
}

export default DeletePaymentInfo;
