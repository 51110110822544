import React from 'react';
import styles from './DeletePaymentForm.module.scss';

import { RadioButton } from "primereact/radiobutton";
import Button from '../../../../components/Button/Button';

function DeletePaymentForm({onSubmit, onChange, onReset, formData, submitButtonModeStyle = ['full', 'regular'], submitting, errorDetail, checks = [], selectedCheck, onSetSelectedCheck}) {

    return (
        <form onSubmit={onSubmit}>
            <div className={[styles.wrapperInput, errorDetail && styles.error].join(' ')}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 10L14 14M6.66667 11.3333C4.08934 11.3333 2 9.244 2 6.66667C2 4.08934 4.08934 2 6.66667 2C9.244 2 11.3333 4.08934 11.3333 6.66667C11.3333 9.244 9.244 11.3333 6.66667 11.3333Z" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <input
                    type='text'
                    name='number'
                    required
                    value={formData.number}
                    onChange={onChange}
                />
                <button type='button' disabled={submitting} onClick={onReset}>Reset</button>
                {errorDetail && <div className={styles.errorDetail}>{errorDetail}</div>}
            </div>

            {checks.length > 1 &&
                <div className={styles.wrapperSelectCheck}>
                    <div className={styles.title}>Please select check:</div>

                    {checks.map((check) => {
                        return (
                            <div key={check.id} className={styles.item}>
                                <RadioButton inputId={check.id} name="check" value={check} onChange={(e) => onSetSelectedCheck(e.value)} checked={selectedCheck && selectedCheck.id === check.id} />
                                <label htmlFor={check.id}>{check['carrierLabel']}</label>
                            </div>
                        );
                    })}
                </div>
            }

            <Button name={submitting ? 'Searching...' :'Submit'} type='submit' disabled={submitting || (checks.length > 1 && !selectedCheck)} modeStyle={submitButtonModeStyle} />
        </form>
    );
}

export default DeletePaymentForm;
