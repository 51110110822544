import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './DeletePayment.module.scss';

import logo from '../../assets/images/logo.png';

import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';

import DeletePaymentForm from './components/DeletePaymentForm/DeletePaymentForm';
import Button from '../../components/Button/Button';
import DeletePaymentInfo from './components/DeletePaymentInfo/DeletePaymentInfo';
import DeleteCheckDialog from '../../components/DeleteCheckDialog/DeleteCheckDialog';
import { deletePaymentService } from '../../features/deletePayment/deletePaymentService';
import Pagination from '../../components/Pagination/Pagination';

function DeletePayment() {
    const [showFirstScreen, setShowFirstScreen] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [showFreightBillsTable, setShowFreightBillsTable] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [isLoadingClaims, setIsLoadingClaims] = useState(false);
    const [isLoadingFreightBills, setIsLoadingFreightBills] = useState(false);

    const toast = useRef(null);
    const [errorDetail, setErrorDetail] = useState(null);
    const [formData, setFormData] = useState({number: ''});

    const [user, setUser] = useState(null);
    const [checks, setChecks] = useState([]);
    const [selectedCheck, setSelectedCheck] = useState(null);
    const [claims, setClaims] = useState([]);
    const [freightBills, setFreightBills] = useState([]);
    const [selectedClaim, setSelectedClaim] = useState(null);

    const queryParams = new URLSearchParams(useLocation().search);

    const [pageIndexClaims, setPageIndexClaims] = useState(1);
	const [totalRecordsClaims, setTotalRecordsClaims] = useState(10);

    const [pageIndexFreightBills, setPageIndexFreightBills] = useState(1);
	const [totalRecordsFreightBills, setTotalRecordsFreightBills] = useState(10);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const userId = queryParams.get("user_id");

                if (userId) {
                    const data = await deletePaymentService.getUser(userId);
                    setUser(data['user']);
                }
            }
            catch (error) {
            }
        };

        fetchUser();
    }, []);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        if (checks.length > 1 && selectedCheck && selectedCheck.id) {
            setShowFirstScreen(false);
            fetchClaims(e, selectedCheck.id, 1);
        } else {
            fetchChecks(e);
        }
    }

    const fetchChecks = async (e) => {
        if (e) {
            e.preventDefault();
        }

        if (!formData.number || submitting) {
            return;
        }

        setSubmitting(true);
        setErrorDetail(null);

        try {
            const data = await deletePaymentService.getCheck(formData.number);
            const checks = data['data'] || [];

            setChecks(checks);
            setShowFreightBillsTable(false);
            setSelectedClaim(null);
            setFreightBills([]);

            if (checks.length === 1) {
                setShowFirstScreen(false);
                fetchClaims(e, checks[0].id, 1);
                setSelectedCheck(checks[0]);
            }

            if (checks.length > 1) {
                setShowFirstScreen(true);
            }

            if (!data || checks.length === 0) {
                setErrorDetail('The number entered is incorrect');
            }
        }
        catch (error) {
            setErrorDetail(error.message);
            toast.current.show({severity:'error', summary: 'Error', detail: error.message, life: 3000});
        }
        finally {
            setSubmitting(false);
        }
    };

    const fetchClaims = async (e, selectedCheckId, page = 1) => {
        if (e) {
            e.preventDefault();
        }

        if (!selectedCheckId || isLoadingClaims) {
            return;
        }

        setIsLoadingClaims(true);

        try {
            const data = await deletePaymentService.getClaims(selectedCheckId, page);

            if (data['data']) {
                setClaims(data['data']);

                if (data['info']) {
                    setTotalRecordsClaims(data['info']['count']);
                }
            } else {
                setErrorDetail('The number entered is incorrect');
            }
        } catch (error) {
            toast.current.show({severity:'error', summary: 'Error', detail: error.message, life: 3000});
            setErrorDetail(error.message);
        } finally {
            setIsLoadingClaims(false);
        }
    }

    const handleResetForm = () => {
        setFormData({number: ''});
        setErrorDetail(null);
        setShowFirstScreen(true);
        setShowFreightBillsTable(false);
        setChecks([]);
        setSelectedCheck(null);
        setClaims([]);
        setFreightBills([]);
        setSelectedClaim(null);
        setTotalRecordsClaims(10);
        setTotalRecordsFreightBills(10);
        setPageIndexClaims(1);
        setPageIndexFreightBills(1);
    };

    const handleOpenDeleteDialog = () => {
        setShowDeleteDialog(true);
    }


    const handleCancelDeleteDialog = () => {
        setShowDeleteDialog(false);
    }

    const fetchFreightBills = async (id, page = 1) => {
        if (!id || isLoadingFreightBills || selectedClaim === id) {
            return;
        }

        setSelectedClaim(id);
        setIsLoadingFreightBills(true);
        setShowFreightBillsTable(true);

        try {
            const data = await deletePaymentService.getFreightBills(id, page);
            setFreightBills(data['data'] || []);

            if (data['info']) {
                setTotalRecordsFreightBills(data['info']['count']);
            }
        }
        catch (error) {
            setShowFreightBillsTable(false);
            toast.current.show({severity:'error', summary: 'Error', detail: error.message, life: 3000});
        }
        finally {
            setIsLoadingFreightBills(false)
        }
    }

    const handlePageChangeClaims = (page) => {
		setPageIndexClaims(page);
        fetchClaims(null, selectedCheck.id, page);
	}

    const handlePageChangeFreightBills = (page) => {
		setPageIndexFreightBills(page);
        fetchFreightBills(selectedClaim, page);
	}

    const handleContinueDialog = () => {
        handleCancelDeleteDialog();
        handleResetForm();
        setShowFirstScreen(true);
    }

    return (
        <div className={styles.wrapperDeletePaymentScreen}>
            <Toast ref={toast} />

            {submitting && <div className={styles.wrapperProgressSpinner}>
                <ProgressSpinner style={{width: '80px', height: '80px'}} strokeWidth='4' fill='var(--surface-ground)' animationDuration='.5s' />
            </div>}

            {
                showFirstScreen ? (
                    <div className={styles.firstScreen}>
                        <img src={logo} alt='' />
                        <h1>Delete a check and its payments</h1>
                        <p>Enter the number of the check <br /> you want to delete</p>

                        <DeletePaymentForm
                            onSubmit={handleSubmit}
                            onChange={handleChange}
                            onReset={handleResetForm}
                            formData={formData}
                            submitting={submitting}
                            errorDetail={errorDetail}
                            checks={checks}
                            selectedCheck={selectedCheck}
                            onSetSelectedCheck={setSelectedCheck}
                        />

                    </div>
                ) : (
                    <div className={styles.mainScreen}>
                        {showDeleteDialog && (
                            <DeleteCheckDialog
                                user={user}
                                checkId={selectedCheck['id']}
                                number={formData.number}
                                onCancelDialog={handleCancelDeleteDialog}
                                onContinue={handleContinueDialog}
                            />
                        )}

                        <div className={styles.headerScreen}>
                            <div className={styles.text}>Enter the number of the check you want to delete</div>
                            {user && <div className={styles.userName}>
                                <div className={styles.avatar}>
                                    <svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M7.84455 20.6618C4.15273 20.6618 1 20.0873 1 17.7865C1 15.4858 4.13273 13.3618 7.84455 13.3618C11.5364 13.3618 14.6891 15.4652 14.6891 17.766C14.6891 20.0658 11.5564 20.6618 7.84455 20.6618Z" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M7.83725 10.1735C10.26 10.1735 12.2236 8.2099 12.2236 5.78718C12.2236 3.36445 10.26 1.3999 7.83725 1.3999C5.41452 1.3999 3.44998 3.36445 3.44998 5.78718C3.4418 8.20172 5.3918 10.1654 7.80634 10.1735C7.81725 10.1735 7.82725 10.1735 7.83725 10.1735Z" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>

                                </div>
                                {user['full_name']}
                            </div>}
                        </div>

                        <div className={styles.wrapperActions}>
                            <DeletePaymentForm
                                onSubmit={fetchChecks}
                                onChange={handleChange}
                                onReset={handleResetForm}
                                formData={formData}
                                submitButtonModeStyle={['regular']}
                                submitting={submitting}
                                errorDetail={errorDetail}
                            />

                            {selectedCheck && <Button name='Delete Check' type='button' modeStyle={['redRegular']} icon='trash' onClick={handleOpenDeleteDialog} /> }
                        </div>

                        {selectedCheck &&
                            <DeletePaymentInfo
                                amount={selectedCheck['amount']}
                                checkCurrency={selectedCheck['checkCurrency']}
                                currencyRate={selectedCheck['currencyRate']}
                                checkDate={selectedCheck['checkDate']}
                                checkIssued={selectedCheck['checkIssued']}
                                claimPaymentNumber={selectedCheck['claimPaymentNumber']}
                            />
                        }

                        <div className={styles.wrapperTables}>
                            <div className={styles.wrapperTable}>
                                {isLoadingClaims && <div className={styles.wrapperProgressSpinner}>
                                    <ProgressSpinner style={{width: '40px', height: '40px'}} strokeWidth='4' fill='var(--surface-ground)' animationDuration='.5s' />
                                </div>}
                                <div className={styles.title}>Claims paid with this check</div>
                                <div className={styles.table}>
                                    <div className={styles.tableHeader}>
                                        <div className={styles.tableCol}>Claim Number</div>
                                        <div className={styles.tableCol}>Claim Paid Amount</div>
                                        <div className={styles.tableCol}>Claim Open Amount</div>
                                        <div className={styles.tableCol}>SCAC</div>
                                        <div className={styles.tableCol}>Carrier</div>
                                        <div className={styles.tableCol}>Client</div>
                                    </div>

                                    <div className={styles.tableBody}>
                                        {claims.length === 0 && (
                                            <div className={styles.tableRow}>
                                                <div className={styles.tableCol}>Empty</div>
                                            </div>
                                        )}
                                        {claims.map((item, key) =>(
                                            <div
                                                key={key}
                                                className={[styles.tableRow, selectedClaim === item['id'] && styles.active].join(' ')}
                                                onClick={() => fetchFreightBills(item['id'])}
                                            >
                                                <div className={styles.tableCol}>{item['claimNumber']}</div>
                                                <div className={styles.tableCol}>{item['claimPaidAmount']}</div>
                                                <div className={styles.tableCol}>{item['claimOpenAmount']}</div>
                                                <div className={styles.tableCol}>{item['scacAndSuffix']}</div>
                                                <div className={styles.tableCol}>{item['carrierName']}</div>
                                                <div className={styles.tableCol}>{item['clientName']}</div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className={styles.tableFooter}>
                                        <div className={styles.text}>Showing {claims.length} of {totalRecordsClaims}</div>
                                        <Pagination
                                            pageIndex={pageIndexClaims}
                                            totalRecords={totalRecordsClaims}
                                            onPageChange={handlePageChangeClaims}
                                        />
                                    </div>
                                </div>
                            </div>
                            {showFreightBillsTable && (
                                <div className={styles.wrapperTable}>
                                    {isLoadingFreightBills && <div className={styles.wrapperProgressSpinner}>
                                        <ProgressSpinner style={{width: '40px', height: '40px'}} strokeWidth='4' fill='var(--surface-ground)' animationDuration='.5s' />
                                    </div>}
                                    <div className={styles.title}>Claim freight bills in the Claim</div>
                                    <div className={styles.table}>
                                        <div className={styles.tableHeader}>
                                            <div className={styles.tableCol}>Claim Freight Bill Pro #</div>
                                            <div className={styles.tableCol}>Claim Freight Bill Open Amount</div>
                                            <div className={styles.tableCol}>Claim Freight Total Amount</div>
                                        </div>
                                        <div className={styles.tableBody}>
                                            {freightBills.map((item, key) =>(
                                                <div key={key} className={styles.tableRow}>
                                                    <div className={styles.tableCol}>{item['proNumber']}</div>
                                                    <div className={styles.tableCol}>{item['openAmount']}</div>
                                                    <div className={styles.tableCol}>{item['totalAmount']}</div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className={styles.tableFooter}>
                                            <div className={styles.text}>Showing {freightBills.length} of {totalRecordsFreightBills}</div>
                                            <Pagination
                                                pageIndex={pageIndexFreightBills}
                                                totalRecords={totalRecordsFreightBills}
                                                onPageChange={handlePageChangeFreightBills}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )
            }
        </div>
    );
}

export default DeletePayment;
