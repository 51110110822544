
import React, { useState } from 'react';

import styles from './Pagination.module.scss';

function Pagination({ pageIndex, totalRecords, onPageChange, pageSize = 200}) {
    const totalPages = Math.ceil(totalRecords / pageSize);

    const handlePageChange = (newPage) => {
        onPageChange(newPage);
    };

    const handlePrevious = () => {
        if (pageIndex > 1) handlePageChange(pageIndex - 1);
    };

    const handleNext = () => {
        if (pageIndex < totalPages) handlePageChange(pageIndex + 1);
    };

    return (
        <div className={styles.wrapperPagination}>
            <button className={styles.btnPrev} onClick={handlePrevious} disabled={pageIndex === 1}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.66663 6L6.66663 8L8.66663 10" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="square"/>
                </svg>
            </button>
            {Array.from({ length: totalPages }, (_, index) => index + 1).map(page => (
                <button
                    className={[page === pageIndex && styles.active].join(' ')}
                    key={`page-${page}`}
                    disabled={page === pageIndex}
                    onClick={() => handlePageChange(page)}
                >
                    {page}
                </button>
            ))}
            <button className={styles.btnNext} onClick={handleNext} disabled={pageIndex === totalPages}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.33337 6L9.33337 8L7.33337 10" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="square"/>
                </svg>
            </button>
        </div>
    );
};

export default Pagination;
