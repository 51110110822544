import React, {useMemo, useState} from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import styles from './App.module.scss';

import DeletePayment from './pages/DeletePayment/DeletePayment';
import Header from './components/Header/Header';

function App() {
    const initialRoutes = useMemo(() => {
        return [
            {
                name: 'Delete Payment',
                path: '/delete-check',
                element: <DeletePayment />,
            },
        ]
    }, []);
    const [routes] = useState([...initialRoutes]);

    return (
        <div className={styles.wrapperApp}>
            <div className={styles.wrapperAppContent}>
                <Routes>
                    {routes.map((route, index) => (
                        <Route key={index} path={route.path} element={route.element} />
                    ))}
                    <Route path='*' element={<Navigate to={routes[0].path} />} />
                </Routes>
            </div>
        </div>
    );
}

export default App;
